import { BigNumber } from '@ethersproject/bignumber'
import { CurrencyAmount, JSBI, Token } from '@sushiswap/core-sdk'
import { useActiveWeb3React } from 'app/services/web3'
import { useTransactionAdder } from 'app/state/transactions/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BigNumber as BigNumberJS } from 'bignumber.js'

import { useSculptorLoopContract } from '../useContract'
import { LOOP_BORROW_RATIO } from 'app/config/sculptor/loopBorrowRatio'
import { tryParseAmount } from 'app/functions'
import { GET_TOKEN } from 'app/config/tokens'

function useLoop(loopAddress: string) {
  const { account, chainId } = useActiveWeb3React()

  const addTransaction = useTransactionAdder()
  const loopContract = useSculptorLoopContract(loopAddress)

  const [isLooping, setIsLooping] = useState<boolean>(false)

  const loop = useCallback(
    async (currencyAmount: CurrencyAmount<Token> | undefined, leverage: number) => {
      if (currencyAmount && chainId) {
        try {
          setIsLooping(true)
          const loopBorrowRatio = LOOP_BORROW_RATIO[leverage.toString()]
          const tx = await loopContract?.loop(
            currencyAmount.currency.address,
            currencyAmount.quotient.toString(),
            2, // interestRateMode hardcoded,
            loopBorrowRatio[0],
            loopBorrowRatio[1]
          )
          return addTransaction(tx, { summary: 'Looped' })
        } catch (e) {
          console.error('Looping error:', e)
          return e
        } finally {
          setIsLooping(false)
        }
      }
    },
    [addTransaction, loopContract, chainId]
  )

  const useCalculateCollateralBorrow = (
    amount: CurrencyAmount<Token> | undefined,
    leverage: number
  ): CollateralBorrow => {
    const { chainId } = useActiveWeb3React()
    const loopContract = useSculptorLoopContract(loopAddress)

    const { SCULPT } = GET_TOKEN(chainId)

    const defaultResponse: CollateralBorrow = {
      newCollateral: CurrencyAmount.fromRawAmount(amount?.currency ?? SCULPT, '0'),
      newBorrow: CurrencyAmount.fromRawAmount(amount?.currency ?? SCULPT, '0'),
    }
    const [collateralBorrow, setCollateralBorrow] = useState<CollateralBorrow>(defaultResponse)
    const fetchTotalStakeAmount = useCallback(async () => {
      if (chainId && amount) {
        try {
          const loopBorrowRatio = LOOP_BORROW_RATIO[leverage.toString()]
          const collateralBorrow = await loopContract?.getCollateralBorrow(
            amount?.quotient.toString(),
            loopBorrowRatio[0],
            loopBorrowRatio[1]
          )
          const response = {
            newCollateral: CurrencyAmount.fromRawAmount(amount?.currency ?? SCULPT, collateralBorrow[0].toString()),
            newBorrow: CurrencyAmount.fromRawAmount(amount?.currency ?? SCULPT, collateralBorrow[1].toString()),
          }
          setCollateralBorrow(response)
        } catch (error) {
          // setTotalStakedAmount(defaultResponse)
          throw error
        }
      }
    }, [chainId, loopContract, amount, leverage, SCULPT])

    useEffect(() => {
      if (chainId && loopContract) {
        fetchTotalStakeAmount()
      }
      const refreshInterval = setInterval(fetchTotalStakeAmount, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, fetchTotalStakeAmount, loopContract, amount])

    return collateralBorrow
  }

  return {
    isLooping,
    loop,
    useCalculateCollateralBorrow,
  }
}

export interface CollateralBorrow {
  newCollateral: CurrencyAmount<Token>
  newBorrow: CurrencyAmount<Token>
}

export default useLoop
